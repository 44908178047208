const styles = (theme) => ({
    item: {
        textAlign: "center",
        paddingBottom: theme.spacing(2),
    },
    text: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    }
});

export default styles;