import { lazyRetry as lazy } from "@utils/lazyRetry";

export const COACH_ENQUIRY_COMPLETE_PATH = "/coach-enquiry-complete";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: COACH_ENQUIRY_COMPLETE_PATH,
        layout: "coachingEnquiry",
        component: lazy(() => import("./index")),
    },
];

export default routes;
