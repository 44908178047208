import { THEMES } from "@src/constants";

const styles = (theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: "none",
                  backgroundColor: theme.palette.primary.main,
              }
            : {
                  backgroundColor: theme.palette.background.white,
              }),
    },
    logo: {
        maxHeight: "34px",
    },
    toolbar: {
        minHeight: "64px",
    },
});

export default styles;
