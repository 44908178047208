import _ from "lodash";
import moment from "moment-timezone";

export const getAttachmentSource = (attachment) => {
    const rawFile = _.get(attachment, "file");
    const name = _.get(attachment, "name");
    const extension = /[^.]+$/.exec(name);
    const id = _.get(attachment, "id");

    return attachment
        ? {
              id,
              name,
              src: `data:image/${extension};base64,${rawFile}`,
          }
        : attachment;
};

export const getImageDimensions = (file) => {
    return new Promise(function (resolved, rejected) {
        let i = new Image();

        i.onload = function () {
            resolved({ w: i.width, h: i.height });
        };
        i.src = file;
    });
};

export const camelCaseKeysToUnderscore = (obj) => {
    if (typeof obj !== "object") return obj;

    for (const oldName in obj) {
        const newName = oldName.replace(/([A-Z])/g, function ($1) {
            return "_" + $1.toLowerCase();
        });

        if (newName !== oldName) {
            //avoid a ReferenceError in strict mode
            if (obj.hasOwnProperty(oldName)) {
                obj[newName] = obj[oldName];
                delete obj[oldName];
            }
        }

        if (typeof obj[newName] == "object") {
            obj[newName] = camelCaseKeysToUnderscore(obj[newName]);
        }
    }

    return obj;
};

export const downloadFile = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
};

export const keysToSnake = (obj) => {
    if (typeof obj !== "object" || obj === null) return obj;

    const newObj = Array.isArray(obj) ? [...obj] : { ...obj };

    for (const oldName in newObj) {
        const newName = oldName.replace(/([A-Z])/g, function ($1) {
            return "_" + $1.toLowerCase();
        });

        if (newName !== oldName) {
            //avoid a ReferenceError in strict mode
            if (newObj.hasOwnProperty(oldName)) {
                newObj[newName] = newObj[oldName];
                delete newObj[oldName];
            }
        }

        if (typeof newObj[newName] == "object") {
            newObj[newName] = keysToSnake(newObj[newName]);
        }
    }

    return newObj;
};

export const filterFormikChangedValues = (values, initialPayload) =>
    Object.entries(values).reduce((acc, [key, value]) => {
        if (typeof value === "object") {
            acc[key] = filterFormikChangedValues(value, initialPayload[key]);
        } else {
            const valueChanged = initialPayload[key] !== value;
            if (valueChanged) {
                acc[key] = value;
            }
        }
        return acc;
    }, {});

const toCamel = (str) => {
    return str.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
    });
};

const isObject = (obj) => {
    return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function";
};

export const keysToCamel = (obj) => {
    if (isObject(obj)) {
        const n = {};

        Object.keys(obj).forEach((k) => {
            n[toCamel(k)] = keysToCamel(obj[k]);
        });

        return n;
    } else if (Array.isArray(obj)) {
        return obj.map((i) => {
            return keysToCamel(i);
        });
    }

    return obj;
};

export const filteredObject = (object) => {
    return _.cloneDeepWith(object, function customizer(v) {
        return _.isObject(v) ? _.cloneDeepWith(_.omitBy(v, _.isUndefined), _.after(2, customizer)) : undefined;
    });
};

export const formikTouchedErrors = (errors, touched) =>
    Object.keys(errors)
        .map((errorKey) => {
            const errorValue = errors[errorKey];
            if (Array.isArray(errorValue)) {
                return errorValue
                    .map((subError) => {
                        return typeof subError === "object"
                            ? Object.keys(subError)
                                  .filter((subKey) => !!_.get(touched, `[${errorKey}][${subKey}]`))
                                  .map((subKey) => subError[subKey])
                                  .join("")
                            : "";
                    })
                    .join("");
            }

            return touched[errorKey] ? errorValue : "";
        })
        .join("");

export const getFormattedPrice = (data) => {
    return isNaN(data) || data === ""
        ? ""
        : `€ ${new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
          })
              .format(data)
              .replace(/.$/, "")
              .trim()}`;
};

export const getFormattedDate = (date, timeIncluded) => {
    return date && moment(date).isValid() ? (timeIncluded ? moment(date).format("DD/MM/YYYY HH:mm") : moment(date).format("DD/MM/YYYY")) : date;
};
