// AWS Amplify Configuration Template - autogenerated at deploy time
const config = {
    domain: "https://",
    s3: {
        REGION: "eu-central-1",
        BUCKET: "xfaktor-client-stage-sb-upload",
    },
    // Support tenant cognito config
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://mzee378kk7.execute-api.eu-central-1.amazonaws.com/stage/v1",
        CORE_BFF_URL: "/v1",
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_vkWdJ4MWN",
        APP_CLIENT_ID: "qh9ups4tnt53cnb5h1u7ic4um",
        COGNITO_DNS_NAME: "login.one-staging-sb.platform-staging.haufe.io",
    },
    locales: {
        URL: "https://locales.xfaktor-stage-sb.app.semigator.net",
    },
    auth: {
        ONEX_AUTH_URL: "https://auth.one-staging-sb.platform-staging.haufe.io/v1",
    },
    officialDomain: {
        DOMAIN: "",
    },
    emails: {
        FROM_XFAKTOR: "",
    },
};

export default config;
