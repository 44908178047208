import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bookings: [],
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        initCart() {
            const localStorageCart = localStorage.getItem("cart");
            return localStorageCart ? JSON.parse(localStorageCart) : initialState;
        },

        addBookingToCart(state, action) {
            const booking = action.payload;
            const others = state.bookings.filter((b) => b.productId !== booking.productId || b.productItemId !== booking.productItemId);

            const newState = {
                ...state,
                bookings: [...others, booking],
            };
            localStorage.setItem("cart", JSON.stringify(newState));
            return newState;
        },

        removeBookingFromCart(state, action) {
            const { productId = "", productItemId = "" } = action.payload;
            const others = state.bookings.filter((b) => b.productId !== productId || b.productItemId !== productItemId);
            const newState = {
                ...state,
                bookings: [...others],
            };
            localStorage.setItem("cart", JSON.stringify(newState));
            return newState;
        },

        removeBookings(state, action) {
            const excluded = action.payload;
            const shouldExclude = (b) => excluded.some((invalid) => invalid.productId === b.productId && invalid.productItemId === b.productItemId);
            const others = state.bookings.filter((booking) => !shouldExclude(booking));

            const newState = {
                ...state,
                bookings: [...others],
            };

            localStorage.setItem("cart", JSON.stringify(newState));
            return newState;
        },

        clearCart() {
            localStorage.setItem("cart", JSON.stringify(initialState));
            return initialState;
        },
    },
});

export const reducer = cartSlice.reducer;
export const cartActions = cartSlice.actions;
export default cartSlice;
