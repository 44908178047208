import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Box, ButtonBase, Divider, Grid, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useAuth from "@hooks/use_auth";
import { useSelector } from "@store";
import _ from "lodash";
import styles from "./index.styles";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as UserIcon } from "@assets/img/user/user.svg";
import { ReactComponent as CartIcon } from "@assets/img/user/cart.svg";
import { ReactComponent as QuestionMarkIcon } from "@assets/img/user/question_mark.svg";
import { ReactComponent as SettingsIcon } from "@assets/img/user/settings.svg";
import { ReactComponent as LogoutIcon } from "@assets/img/user/logout.svg";
import { ENQUIRIES_LIST_PATH } from "@pages/coach/enquiry_list/index.routes";

const useStyles = makeStyles(styles);

const Account = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const ref = useRef(null);
    const user = useSelector((state) => state.user);
    const xsDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const theme = useTheme();

    const { logout } = useAuth();

    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
        } catch (err) {
            if (_.get(err, "name") === "NotAuthorizedException") {
                handleClose();
                await logout();
            } else {
                enqueueSnackbar(t("account.dropdown.logoutError"), {
                    variant: "error",
                });
            }
        }
    };

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center" justifyContent="flex-end" component={ButtonBase} onClick={handleOpen} ref={ref}>
                <UserIcon className={classes.avatar} />
                {user && !xsDown && (
                    <Typography className={classes.name} variant="h5">
                        {_.get(user, "firstName", "") + " " + _.get(user, "lastName", "")}
                    </Typography>
                )}
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                PaperProps={{ className: classes.popover }}
                anchorEl={ref.current}
                open={isOpen}
            >
                <Typography className={classes.category} paddingTop={1} paddingLeft={2}>
                    {t("account.dropdown.activities")}
                </Typography>
                <Grid container direction="column" paddingTop={1}>
                    <Grid item className={classes.item}>
                        <MenuItem component={RouterLink} to="/orders" onClick={handleClose}>
                            <Grid container paddingLeft={2}>
                                <Grid item>
                                    <CartIcon className={classes.hintIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography>{t("account.dropdown.orders")}</Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Grid>
                    <Grid item className={classes.item}>
                        <MenuItem component={RouterLink} to={ENQUIRIES_LIST_PATH} onClick={handleClose}>
                            <Grid container paddingLeft={2}>
                                <Grid item>
                                    <QuestionMarkIcon className={classes.hintIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography>{t("account.dropdown.enquiries")}</Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Grid>
                </Grid>
                <Typography className={classes.category} paddingTop={1} paddingLeft={2}>
                    {t("account.dropdown.user")}
                </Typography>
                <Grid container direction="column" paddingTop={1}>
                    <Grid item className={classes.item}>
                        <MenuItem component={RouterLink} to="/dashboard/account/profile" onClick={handleClose}>
                            <Grid container paddingLeft={2}>
                                <Grid item>
                                    <UserIcon className={classes.hintIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography>{t("account.dropdown.profile")}</Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Grid>
                </Grid>
                <Typography className={classes.category} paddingTop={1} paddingLeft={2}>
                    {t("account.dropdown.company")}
                </Typography>
                <Grid container direction="column" paddingTop={1}>
                    <Grid item className={classes.item}>
                        <MenuItem component={RouterLink} to="/dashboard/account/company" onClick={handleClose}>
                            <Grid container paddingLeft={2}>
                                <Grid item>
                                    <SettingsIcon className={classes.hintIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography>{t("account.dropdown.settings")}</Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Grid>
                </Grid>
                <Box padding={2}>
                    <Divider />
                </Box>
                <Grid container direction="column">
                    <Grid item className={classes.item}>
                        <MenuItem onClick={handleLogout}>
                            <Grid container paddingLeft={2} paddingBottom={1}>
                                <Grid item>
                                    <LogoutIcon className={classes.hintIcon} />
                                </Grid>
                                <Grid item>
                                    <Typography color={theme.palette.error.main}>{t("account.dropdown.logout")}</Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </Grid>
                </Grid>
            </Menu>
        </React.Fragment>
    );
};

export default Account;
