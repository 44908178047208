const styles = (theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        background: theme.palette.background.white,
    },
    badge: {
        display: "start",
        color: "#000",
    },
    avatar: {
        height: 20,
        width: 20,
        marginRight: theme.spacing(1),
    },
    toolbar: {
        minHeight: 64,
    },
    searchButton: {
        color: "white",
        "& svg": {
            color: "white",
        },
    },
    searchIcon: {
        width: 30,
        height: 30,
    },
    iconsWrapper: {
        [theme.breakpoints.down("xs")]: {
            minWidth: 90,
        },
    },
    mobileSearch: {
        width: "100%",
        paddingBottom: 10,
    },
});

export default styles;
