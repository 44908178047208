import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        path: "/404",
        style: "onboarding",
        layout: "auth",
        component: lazy(() => import("./index")),
    },
];

export default routes;
