import React, { createContext, Suspense, useEffect, useReducer } from "react";
import SplashScreen from "@components/splash_screen";
import { callApi, Endpoints } from "@utils/api";
import { setUser } from "@slices/user";
import { dispatchTenant, retrieveTenant } from "@slices/company";
import { useDispatch } from "@store";
import _ from "lodash";
import { Amplify, Auth } from "aws-amplify";
import { CognitoRefreshToken, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import TAwsConfig from "../awsConfig";
import LoadingScreen from "@components/loading_screen";
import { useSnackbar } from "notistack";
import { cartActions } from "@slices/cart";
import i18n from "i18next";

export const AMPLIFY_AUTH_CONFIGURED = "AMPLIFY_AUTH_CONFIGURED";
export const INITIALISE = "INITIALISE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_FAILED_MESSAGE = "Login failed. Please try again later";
export const CHANGE_TEMP_PASSWORD = "CHANGE_TEMP_PASSWORD";

export const LOGOUT = "LOGOUT";

const initialAuthState = {
    isAmplifyAuthConfigured: false,
    isInitialised: false,
    isLoggingIn: false,
    isAuthenticated: false,
    authenticatedTenantId: null,
    authenticatedUserId: null,
    customerName: null,
    customerNickname: null,
    customerLogo: null,
    needsPermanentPass: false,
};

const USERPOOL_ID = "USERPOOL_ID";
const APP_CLIENT_ID = "APP_CLIENT_ID";
const COGNITO_DOMAIN = "COGNITO_DOMAIN";

const parseAuthUser = async (authUser) => {
    const userId = _.get(authUser, "attributes.custom:profile_id", _.get(authUser, "challengeParam.userAttributes.custom:profile_id", ""));
    const userSession = authUser.getSignInUserSession();
    const currentIdToken = userSession.getIdToken();
    const tenantId = _.get(currentIdToken, "payload.tenant_id");

    const { data, error } = await callApi(Endpoints.GetUser(userId), {});

    if (_.get(error, "status") === 401) {
        return { error };
    }

    if (error) {
        throw new Error("Cannot obtain user information. Please try again later.");
    }

    const firstName = _.get(data, "user.firstName", "");
    const lastName = _.get(data, "user.lastName", "");
    const salutation = _.get(data, "user.salutation", "");
    const language = _.get(data, "user.language", "en");
    const email = _.get(data, "user.email", "");
    const role = _.get(data, "user.role", "");

    return {
        tenantId,
        firstName,
        lastName,
        email,
        salutation,
        id: userId,
        language,
        role,
    };
};

const configureAmplify = (userPoolId, appClientId, domain) => {
    Amplify.configure({
        Auth: {
            authenticationFlowType: "USER_PASSWORD_AUTH",
            mandatorySignIn: true,
            region: TAwsConfig.cognito.REGION,
            userPoolId: userPoolId,
            userPoolWebClientId: appClientId,
            oauth: {
                domain: domain,
                redirectSignIn: domain + "/sso-login-success",
                redirectSignOut: domain,
                scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
                clientId: appClientId,
                responseType: "code",
            },
        },
    });
};

const refreshSession = async () => {
    const userPoolId = localStorage[USERPOOL_ID];
    const appClientId = localStorage[APP_CLIENT_ID];
    const domain = localStorage[COGNITO_DOMAIN];

    if (_.isNil(userPoolId) || _.isNil(appClientId) || _.isNil(domain)) {
        throw new Error("Invalid amplify configuration");
    } else {
        configureAmplify(localStorage[USERPOOL_ID], localStorage[APP_CLIENT_ID], localStorage[COGNITO_DOMAIN]);
        return Auth.currentSession()
            .then((data) => data)
            .then(
                (data) =>
                    new Promise((resolve, reject) => {
                        if (!data || !data.accessToken || !data.refreshToken.token || !data.accessToken.payload.username) {
                            reject("no token found");
                        }
                        const userPool = new CognitoUserPool({
                            UserPoolId: localStorage[USERPOOL_ID],
                            ClientId: localStorage[APP_CLIENT_ID],
                        });
                        const cognitoUser = new CognitoUser({
                            Username: data.accessToken.payload.username,
                            Pool: userPool,
                        });
                        cognitoUser.refreshSession(new CognitoRefreshToken({ RefreshToken: data.refreshToken.token }), async (err, session) => {
                            if (err) {
                                reject(err.code);
                            } else {
                                return resolve();
                            }
                        });
                    })
            );
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case AMPLIFY_AUTH_CONFIGURED: {
            return {
                ...state,
                isAmplifyAuthConfigured: true,
            };
        }

        case INITIALISE: {
            const { isAuthenticated, user } = action.payload;
            const { tenantId, id } = user;
            return {
                ...state,
                isAuthenticated,
                authenticatedTenantId: tenantId,
                authenticatedUserId: id,
                isInitialised: true,
            };
        }

        case LOGIN_REQUEST: {
            return { ...state, isLoggingIn: true };
        }

        case LOGIN_SUCCESS: {
            const { tenantId, id } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                isLoggingIn: false,
                authenticatedTenantId: tenantId,
                authenticatedUserId: id,
                needsPermanentPass: false,
            };
        }

        case LOGIN_FAILED: {
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                authenticatedTenantId: null,
                authenticatedUserId: null,
            };
        }

        case LOGOUT: {
            return {
                ...state,
                isAuthenticated: false,
                authenticatedTenantId: null,
                authenticatedUserId: null,
                user: null,
            };
        }
        case CHANGE_TEMP_PASSWORD: {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: false,
                isLoggingIn: true,
                needsPermanentPass: true,
                user: user,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: "JWT",
    amplifyAuthConfigure: () => Promise.resolve(),
    login: () => Promise.resolve(),
    federatedLogin: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    changeTemporaryPassword: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    forgotPasswordSubmit: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const { enqueueSnackbar } = useSnackbar();

    const storeDispatch = useDispatch();

    const amplifyAuthConfigure = async (email) => {
        const { data } = await callApi(Endpoints.GetTenantCognitoForEmail(email), {}, "get", {}, false);

        const userPoolId = _.get(data, "cognito.userPoolId");
        const appClientId = _.get(data, "cognito.appClientId");
        const domain = _.get(data, "cognito.domain");

        configureAmplify(userPoolId, appClientId, domain);
        localStorage.setItem(USERPOOL_ID, userPoolId);
        localStorage.setItem(APP_CLIENT_ID, appClientId);
        localStorage.setItem(COGNITO_DOMAIN, domain);

        dispatch({ type: AMPLIFY_AUTH_CONFIGURED });
    };

    const logoutUnauthorized = async () => {
        dispatch({
            type: LOGIN_FAILED,
            payload: { error: "Access authorized only for clients" },
        });
        await logout();
        enqueueSnackbar("Access authorized only for clients", {
            variant: "error",
        });
        throw new Error("Access authorized only for clients");
    };

    const changeTemporaryPassword = async (params) => {
        const user = await Auth.completeNewPassword(params.user, params.newPassword);

        if (user) {
            const currentUser = await parseAuthUser(user);

            await storeDispatch(setUser(currentUser));
            await dispatch({ type: LOGIN_SUCCESS, payload: currentUser });
            return { user };
        }

        dispatch({
            type: LOGIN_FAILED,
            payload: { error: LOGIN_FAILED_MESSAGE },
        });

        return { error: LOGIN_FAILED_MESSAGE };
    };

    const changePassword = async (values) => {
        return Auth.currentAuthenticatedUser().then((user) => {
            return Auth.changePassword(user, values.oldPassword, values.newPassword);
        });
    };

    const forgotPassword = async (username) => {
        await amplifyAuthConfigure(username);
        const templateLanguage = i18n.language;
        const clientMetadata = {
            from: TAwsConfig.emails.FROM_XFAKTOR,
            template_path: `xfaktor/cognito/reset-password-${templateLanguage}.json`,
            template_params: JSON.stringify({
                url: `https://${TAwsConfig.officialDomain.DOMAIN}`,
                current_year: new Date().getFullYear(),
            }),
        };

        return Auth.forgotPassword(username, clientMetadata);
    };

    const forgotPasswordSubmit = async (username, code, password) => {
        return Auth.forgotPasswordSubmit(username, code, password);
    };

    const login = async (params) => {
        const user = await Auth.signIn(params.username, params.password);
        if (_.get(user, "challengeName") === "NEW_PASSWORD_REQUIRED") {
            await dispatch({
                type: CHANGE_TEMP_PASSWORD,
                payload: { user },
            });
            return user;
        }
        if (user) {
            const currentUser = await parseAuthUser(user);
            if (_.get(currentUser, "error.status") === 401) {
                return await logoutUnauthorized();
            }
            await storeDispatch(setUser(currentUser));
            const { data, error } = await retrieveTenant(currentUser.tenantId);
            if (_.get(error, "status") === 401) {
                return await logoutUnauthorized();
            } else {
                await storeDispatch(dispatchTenant(data));
                await dispatch({ type: LOGIN_SUCCESS, payload: currentUser });
                return { user };
            }
        }

        dispatch({
            type: LOGIN_FAILED,
            payload: { error: LOGIN_FAILED_MESSAGE },
        });
        return { error: LOGIN_FAILED_MESSAGE };
    };

    const federatedLogin = async (providerName) => {
        dispatch({ type: LOGIN_REQUEST });
        await Auth.federatedSignIn({ customProvider: providerName });
    };

    const logout = async () => {
        await Auth.signOut({ global: true });
        dispatch({ type: LOGOUT });
    };

    const attemptAuthenticateUser = async () => {
        const user = await Auth.currentAuthenticatedUser();

        if (!user) {
            throw new Error("User needs to login");
        }

        const parsedUser = await parseAuthUser(user);

        if (_.get(parsedUser, "error.status") === 401) {
            return await logoutUnauthorized();
        }
        const { id, tenantId } = parsedUser;

        await storeDispatch(setUser(parsedUser));
        const { data, error } = await retrieveTenant(tenantId);
        if (_.get(error, "status") === 401) {
            return await logoutUnauthorized();
        } else {
            await storeDispatch(dispatchTenant(data));
            storeDispatch(cartActions.initCart());
            dispatch({
                type: INITIALISE,
                payload: {
                    isAuthenticated: true,
                    user: parsedUser,
                },
            });
            // lang && await i18n.changeLanguage(lang);
            return { id, tenantId };
        }
    };

    useEffect(() => {
        const initialise = async () => {
            try {
                await refreshSession();
                const payload = await attemptAuthenticateUser();
                dispatch({ type: LOGIN_SUCCESS, payload });
            } catch (e) {
                await storeDispatch(setUser(null));
                dispatch({
                    type: INITIALISE,
                    payload: { user: {}, isAuthenticated: false },
                });
            }
        };

        initialise();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "JWT",
                amplifyAuthConfigure,
                login,
                federatedLogin,
                logout,
                changeTemporaryPassword,
                changePassword,
                forgotPassword,
                forgotPasswordSubmit,
            }}
        >
            <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
        </AuthContext.Provider>
    );
};

export default AuthContext;
