import { create } from "apisauce";
import get from "lodash/get";
import { keysToSnake, keysToCamel } from "@utils/utils";
import { Auth } from "aws-amplify";
import TAwsConfig from "../awsConfig";
import config from "../config";

const Api = create({
    baseURL: TAwsConfig.apiGateway.URL,
    timeout: 50000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

const UNEXPECTED_ERROR_MESSAGE = "An unexpected error occurred.";

export const Endpoints = {
    Invitations: config.webapp.INVITATIONS_API_ENDPOINT,
    InvitationsBulk: config.webapp.INVITATIONS_API_ENDPOINT + "/bulk",
    InvitationsS3DownloadUrl: config.webapp.INVITATIONS_ATTACHMENT_API_ENDPOINT + "/download",
    CSVGetAll: config.webapp.INVITATIONS_ATTACHMENT_API_ENDPOINT + "/uploads",
    CSVDeleteAll: config.webapp.INVITATIONS_ATTACHMENT_API_ENDPOINT + "/uploads",
    CSVDeleteOne: (id) => config.webapp.INVITATIONS_ATTACHMENT_API_ENDPOINT + `/uploads/${id}`,
    S3SignedUrl: config.webapp.INVITATIONS_ATTACHMENT_SIGNED_URL,
    InvitationsS3FinalUrl: config.webapp.INVITATIONS_ATTACHMENT_API_ENDPOINT,
    ListCSVStatus: "/csv-status",
    DeleteCSVStatus: "/csv-status",
    GetUser: (id) => `/users/${id}`,
    UpdateUser: (id) => `/users/${id}`,
    GetUsers: `/users`,
    GetTenant: (id) => `/tenants/${id}`,
    GetTenantCognitoForEmail: (email) => `/auth?email=${encodeURIComponent(email)}`,
    UpdateTenant: (id) => `/tenants/${id}`,
    Products: `/products`,
    Product: (productId) => `/products/${productId}`,
    Orders: "/orders",
    Collect: "/products/collect",
    Order: (orderId) => `/orders/${orderId}`,
    Bookings: "/bookings",
    Enquiries: "/enquiries",
    Enquiry: (enquiryId) => `/enquiries/${enquiryId}`,
    Booking: (bookingId) => `/bookings/${bookingId}`,
    CoachingEnquiries: "/coach_enquiries",
    CoachingEnquiry: (enquiryId) => `/coach_enquiries/${enquiryId}`,
    GetCoachingSettings: "/coach_enquiries/settings",
    GetCoachCity: (q) => `/coach_enquiries/locations?q=${q}`,
};

export const UnprotectedEndpoints = {
    Register: `/auth/register`,
};

export const callApi = async (endpoint, params, method = "get", config = {}, addAuthorizationToRequest = true, disabledCamelResponse = false) => {
    let tokenConfig = null;
    try {
        if (addAuthorizationToRequest) {
            const getAwsCredentials = await Auth.currentSession();
            const idToken = getAwsCredentials.getIdToken().jwtToken;

            tokenConfig = {
                headers: idToken ? { Authorization: "Bearer " + idToken } : {},
            };
        }
        const options = tokenConfig ? { ...tokenConfig, ...config } : { ...config };

        const { ok, data, headers, status } = await Api[`${method}`](endpoint, keysToSnake(params), options);

        if (ok) {
            return {
                data: disabledCamelResponse ? data : keysToCamel(data),
                headers,
            };
        }

        const errorMsg = get(data, "error.msg", get(data, "message"));
        const errorCode = get(data, "error.code", get(data, "statusCode"));

        if (status === 401) {
            await Auth.signOut({ global: true });
        }

        if (errorMsg && errorCode) {
            return {
                error: { message: errorMsg, code: errorCode, status: status },
            };
        }

        throw new Error(UNEXPECTED_ERROR_MESSAGE);
    } catch (err) {
        const errorMessage = get(err, "message", UNEXPECTED_ERROR_MESSAGE);
        const errorStatus = get(err, "status", 500);
        return { error: { message: errorMessage, status: errorStatus } };
    }
};
