import { lazy } from "react";

export const ENQUIRIES_LIST_PATH = "/coaching/enquiries";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: ENQUIRIES_LIST_PATH,
        layout: "dashboard",
        title: "Enquiries",
        component: lazy(() => import("./index")),
    },
];

export default routes;
