import { lazyRetry as lazy } from "@utils/lazyRetry";

export const DASHBOARD_PATH = "/dashboard";

const routes = [
    {
        guard: "auth",
        exact: false,
        path: DASHBOARD_PATH,
        layout: "dashboard",
        component: lazy(() => import("./index")),
    },
    {
        guard: "auth",
        exact: false,
        path: "/dashboard/*",
        layout: "dashboard",
        component: lazy(() => import("./index")),
    },
];

export default routes;
