import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { AppBar, Box, Grid, Toolbar, Badge } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Bell as BellIcon } from "react-feather";
import Logo from "@components/logo";
import Account from "@components/user_avatar/index";
import styles from "./index.styles";

const useStyles = makeStyles(styles);

const TopBar = ({ className, ...rest }) => {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/dashboard">
                    <Grid container alignItems="center" direction="row" spacing={2}>
                        <Grid item>
                            <Logo />
                        </Grid>
                    </Grid>
                </RouterLink>
                <Box ml={2} flexGrow={1} />
                <Box ml={2} className={classes.iconsWrapper}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Badge className={classes.badge} overlap="circular">
                                <BellIcon className={classes.avatar} />
                            </Badge>
                        </Grid>
                        <Grid item>
                            <Account />
                        </Grid>
                    </Grid>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
};

export default TopBar;
