/**
 * @fileOverview Configuration
 *
 * @namespace configuration
 *
 */
const config = {
    /**
     * @memberof configuration
     * @property {object}webapp - Webapp defaults.
     */
    webapp: {
        MAX_ATTACHMENT_SIZE: 5000000,
        INVITATIONS_API_ENDPOINT: "/invitations",
        INVITATIONS_ATTACHMENT_API_ENDPOINT: "/invitations-attachment",
        INVITATIONS_ATTACHMENT_SIGNED_URL: "/signed-url",
    },
};

export default config;
