const styles = (theme) => ({
    profile: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        height: 32,
        width: 32,
    },
    avatar: {
        height: 20,
        width: 20,
        marginRight: theme.spacing(2),
        "&:hover": {
            fill: theme.palette.primary.main,
        },
    },
    name: {
        padding: 0,
        color: "#000",
        fontWeight: 500,
    },
    hintIcon: {
        height: 13.35,
        width: 13.35,
        marginRight: theme.spacing(2),
        "&:hover": {
            fill: theme.palette.primary.main,
        },
    },
    category: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.neutral,
        textTransform: "uppercase",
        letterSpacing: "0.06em",
    },
    item: {
        "&:hover": {
            "& $hintIcon": {
                fill: theme.palette.primary.main,
            },
            color: theme.palette.primary.main,
        },
    },
    description: {
        color: "white",
        fontSize: 30,
        marginBottom: theme.spacing(1),
        fontFamily: "Open Sans",
        fontWeight: "800",
    },
    semigatorText: {
        fontFamily: "Open Sans",
        color: "#FFFFFF",
        fontWeight: "bold",
        maxHeight: "15px",
        marginTop: "28px",
        marginLeft: "5px",
    },
    popover: {
        width: 220,
    },
    profileSubtitles: {
        marginLeft: theme.spacing(2),
        fontWeight: "bold",
    },
});

export default styles;
