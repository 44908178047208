import React, { Suspense, Fragment, useEffect } from "react";
import { matchPath, Navigate, Route, Routes, useLocation } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard_layout";
import CoachingEnquiryLayout from "src/layouts/coaching_enquiry_layout";
import LoadingScreen from "@components/loading_screen";
import AuthLayout from "./layouts/auth_layout";
import AuthGuard from "@components/auth_guard";
import GuestGuard from "@components/guest_guard";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import _ from "lodash";

export const pages = [
    require("@pages/auth/login/index.routes"),
    require("@pages/auth/reset_password/index.routes"),
    require("@pages/dashboard/account/company_edit/index.routes"),
    require("@pages/dashboard/account/user_edit/index.routes"),
    require("@pages/dashboard/welcome/index.routes"),
    require("@pages/coach/start/index.routes"),
    require("@pages/coach/enquiry/index.routes"),
    require("@pages/coach/enquiry_complete/index.routes"),
    require("@pages/404/index.routes"),
    require("@pages/coach/enquiry_list/index.routes"),
    require("@pages/coach/enquiry_detail/index.routes"),

    // require("@pages/dashboard/catalog/product/index.routes"),
    // require("@pages/dashboard/catalog/browse/index.routes"),
    // require("@pages/dashboard/account/orders/index.routes"),
    // require("@pages/dashboard/account/orders/order/order_edit/index.routes"),
    // require("@pages/dashboard/account/orders/booking/booking_edit/index.routes"),
    // require("@pages/dashboard/account/enquiry/enquiry_list/index.routes"),
    // require("@pages/dashboard/account/enquiry/enquiry_detail/index.routes"),
    // require("@pages/dashboard/account/cart/index.routes"),
];

const layouts = {
    dashboard: DashboardLayout,
    auth: AuthLayout,
    coachingEnquiry: CoachingEnquiryLayout,
};

const guards = {
    auth: AuthGuard,
    guest: GuestGuard,
};

export const routes = pages.reduce((acc, route) => {
    acc = route.default ? _.concat(acc, route.default) : acc;
    return acc;
}, []);

const RouteComponent = ({ title, ...props }) => {
    const MainComp = props.component;

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <MainComp {...props} />
        </>
    );
};

export const RenderRoutes = ({ routes = [] }) => {
    const { t } = useTranslation();
    const url = useLocation();
    const matchRoute = routes.find((route) => {
        return matchPath(route.path, url.pathname);
    });

    const routeGuard = _.get(matchRoute, "guard");
    const Guard = _.get(guards, routeGuard, Fragment);

    const matchLayout = _.get(matchRoute, "layout");
    const Layout = _.get(layouts, matchLayout, Fragment);

    return (
        <Guard>
            <Layout>
                <Suspense fallback={<LoadingScreen />}>
                    <Routes>
                        {routes.map((route, i) => {
                            const { component, exact, path, title } = route;

                            const translatedTitle = t(title);
                            const baseTitle = `${translatedTitle ? translatedTitle + " - " : ""}Semigator partners`;

                            return <Route key={i} path={path} exact={exact} element={<RouteComponent title={baseTitle} component={component} />} />;
                        })}
                        <Route path="*" element={<Navigate to="/404" />} />
                    </Routes>
                </Suspense>
            </Layout>
        </Guard>
    );
};

export default routes;
