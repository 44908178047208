import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TopBar from "./top_bar";
import { useTranslation } from "react-i18next";
import { useSelector } from "@store";
import clsx from "clsx";
import styles from "./index.styles";

const useStyles = makeStyles(styles);

const DashboardLayout = ({ children }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (user.language) {
            i18n.changeLanguage(user.language);
        }
    }, [i18n, user.language]);

    return (
        <div className={classes.root}>
            <TopBar />
            <div className={clsx(classes.wrapper, {})}>
                <div className={classes.contentContainer}>
                    <div className={clsx(classes.content, classes.contentWide)}>{children}</div>
                </div>
            </div>
        </div>
    );
};

DashboardLayout.propTypes = {
    children: PropTypes.node,
};

export default DashboardLayout;
