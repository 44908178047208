import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider } from "@mui/styles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import GlobalStyles from "@components/global_styles";
import ScrollReset from "@components/scroll_reset";
import useSettings from "@hooks/use_settings";
import { createAppTheme } from "@themes";
import routes, { RenderRoutes } from "./routes";
import { AuthProvider } from "@contexts/auth_context";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
    const { settings } = useSettings();

    const theme = createAppTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <StyledEngineProvider injectFirst utils={MomentUtils}>
                    <LocalizationProvider dateAdapter={MomentUtils}>
                        <SnackbarProvider dense maxSnack={3}>
                            <Router history={history}>
                                <QueryParamProvider adapter={ReactRouter6Adapter}>
                                    <AuthProvider>
                                        <GlobalStyles />
                                        <ScrollReset />
                                        <RenderRoutes routes={routes} />
                                    </AuthProvider>
                                </QueryParamProvider>
                            </Router>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </StyledEngineProvider>
            </StylesProvider>
        </ThemeProvider>
    );
};

export default App;
