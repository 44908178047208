const styles = (theme) => ({
    root: {
        width: '100%',
        minHeight : '100vh',
        backgroundColor:'rgb(245,246,248)',
    },
    container: {
        paddingTop: theme.spacing(12),
        height: '100vh'
    },
    formWrapper: {
        maxWidth: '700px',
        // height: '100vh'
    },
    footer: {
        paddingTop: theme.spacing(4),
    }
});

export default styles;