import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "@slices/user";
import { reducer as companyReducer } from "@slices/company";
import { reducer as cartReducer } from "@slices/cart";

const rootReducer = combineReducers({
    user: userReducer,
    company: companyReducer,
    cart: cartReducer,
});

export default rootReducer;
