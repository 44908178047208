import React from "react";
import DashboardLayout from "@layouts/dashboard_layout";
import PropTypes from "prop-types";

const CoachingEnquiryLayout = ({ children }) => {
    return <DashboardLayout withNavBar={false}>{children}</DashboardLayout>;
};

CoachingEnquiryLayout.propTypes = {
    children: PropTypes.node,
};

export default CoachingEnquiryLayout;
