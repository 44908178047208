import { lazy } from "react";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: "/coaching/enquiries/:id",
        layout: "dashboard",
        title: "Enquiry",
        component: lazy(() => import("./index")),
    },
];

export default routes;
